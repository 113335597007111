import React, {useEffect, useState} from 'react';
import '../css/Menu.scss';
import {ReactComponent as analyseICO} from '../svgFiles/analyse2.svg';

/* global tf_menuNonce */
function Menu() {
    const [userLang, setUserLang] = useState(navigator.language );
    const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
    useEffect(() => {
        function handleResize() {
            setIsPortrait(window.innerWidth < window.innerHeight);
        }

        window.addEventListener('resize', handleResize);

        // Nettoyage de l'événement
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const getPage = () => {
        let nomDeLaPage = '';
        document.addEventListener('DOMContentLoaded', function () {
            // Obtenir le chemin de l'URL
            let cheminURL = window.location.pathname;
            // Diviser le chemin de l'URL en segments en utilisant "/"
            let segmentsURL = cheminURL.split('/');
            // Extraire le dernier segment qui est le nom de la page
            nomDeLaPage = segmentsURL[segmentsURL.length - 2];
            setActualPage(nomDeLaPage);
            console.log(nomDeLaPage);
        });

    }
    const [actualPage, setActualPage] = useState(null);

    useEffect(() => {
        getPage();
    }, [actualPage]);
    function RedirectUser(page) {
        // Utilisez window.location.href pour la redirection
        window.location.href = `/${page}`;
    }
    const translations = {
        en: {
            addiction: isPortrait ? <span>Addiction</span> : <><span>Addiction</span><br /><span>Test</span></>,
            motivation: isPortrait ? <span>Motivation</span> : <><span>Motivation</span><br /><span>Test</span></>,
            analyse: isPortrait ? <span>Results</span> : <><span>Results</span><br /><span>Analysis</span></>,
            plan: isPortrait ? <span>Plan</span> : <><span>Create</span><br /><span>My</span><br /><span>Plan</span></>,
            success: isPortrait ? <span>Trophies</span> : <><span>My</span><br /><span>Trophies</span></>
        },
        fr: {
            addiction: isPortrait ? <span>Addiction</span> : <><span>Test</span><br /><span>d'addiction</span></>,
            motivation: isPortrait ? <span>Motivation</span> : <><span>Test</span><br /><span>de</span><br /><span>motivation</span></>,
            analyse: isPortrait ? <span>Résultats</span> : <><span>Analyse</span><br /><span>des</span><br /><span>résultats</span></>,
            plan: isPortrait ? <span>Plan</span> : <><span>Créer</span><br /><span>mon plan</span></>,
            success: isPortrait ? <span>Succès</span> : <><span>Mes</span><br /><span>succès</span></>
        },
        es: {
            addiction: isPortrait ? <span>Adicción</span> : <><span>Prueba</span><br /><span>de adicción</span></>,
            motivation: isPortrait ? <span>Motivación</span> : <><span>Prueba</span><br /><span>de</span><br /><span>motivación</span></>,
            analyse: isPortrait ? <span>Resultados</span> : <><span>Análisis</span><br /><span>de resultados</span></>,
            plan: isPortrait ? <span>Plan</span> : <><span>Crear</span><br /><span>mi plan</span></>,
            success: isPortrait ? <span>Trofeos</span> : <><span>Mis</span><br /><span>éxitos</span></>
        }
    };

    //todo recuperer par un fetch les info necessaire sur l'utilisateur
    //todo verifier qu'il est logger, l'etat des test
    return <>
        <div className='menu-container'>

            <div   onClick={() => RedirectUser('addiction')}
                className={`menuElement madimi-one-regular ${actualPage === 'addiction' ? 'activeMenuPage' : ''}`}>
                <div id='testAddictionImg'></div>

                <div id='testAddiction' className='centerContent'
                >{ (translations[userLang.slice(0, 2)] || translations["en"]).addiction}</div>
            </div>


            <div   onClick={() => RedirectUser('motivation')}
                className={`menuElement madimi-one-regular ${actualPage === 'motivation' ? 'activeMenuPage' : ''}`}>
                <div id='testMotivationImg'></div>
                <div id='testMotivation'  className='centerContent'>
                    {(translations[userLang.slice(0, 2)] || translations["en"]).motivation}</div>
            </div>

            <div   onClick={() => RedirectUser('synthese')}
                className={`menuElement madimi-one-regular ${actualPage === 'synthese' ? 'activeMenuPage' : ''}`}>
                <div id='testSyntheseImg'></div>
                <div id='testSynthese'  className='centerContent'>
                    {(translations[userLang.slice(0, 2)] || translations["en"]).analyse}</div>
            </div>

            <div  onClick={() => RedirectUser('plan')}
                className={`menuElement madimi-one-regular ${actualPage === 'plan' ? 'activeMenuPage' : ''}`}>
                <div id='generationPlanImg'></div>
                <div id='generationPlan'  className='centerContent'>
                    { (translations[userLang.slice(0, 2)] || translations["en"]).plan}</div>
            </div>
            <div   onClick={() => RedirectUser('trophies')}
                className={`menuElement madimi-one-regular  ${actualPage === 'trophies' ? 'activeMenuPage' : ''}`}>
                <div id='sucessTrophyImg'></div>
                <div id='sucessTrophy'  className='centerContent'>
                    {(translations[userLang.slice(0, 2)] || translations["en"]).success}</div>
            </div>

        </div>

    </>
}

export default Menu;