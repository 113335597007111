import logo from './logo.svg';
import './App.css';
import Menu from "./components/Menu";

function App() {
  return (
    <div className="App">
<Menu />
    </div>
  );
}

export default App;
